import React from 'react';
import { FaWhatsapp, FaClock, FaPhone, FaMapMarkerAlt, FaFacebook, FaInstagram, FaEnvelope } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import SEOHead from './SEOHead';

const ContactPage = () => {
  const { t } = useTranslation();

  const handlePhoneClick = () => {
    window.open(`https://wa.me/${t('contact.info.whatsapp.number')}`, "_blank");
  };

  const handleInstagramClick = () => {
    window.open(`https://www.instagram.com/${t('contact.info.instagram.handle').replace('@', '')}`, "_blank");
  };

  const contactItems = [
    { 
      icon: FaWhatsapp, 
      title: t('contact.info.whatsapp.title'), 
      content: t('contact.info.whatsapp.number'), 
      action: handlePhoneClick 
    },
    { 
      icon: FaPhone, 
      title: t('contact.info.phone.title'), 
      content: t('contact.info.phone.number') +` & `+ t('contact.info.whatsapp.number'),
    },
    { 
      icon: FaEnvelope, 
      title: t('contact.info.email.title'), 
      content: t('contact.info.email.address')
    },
    { 
      icon: FaMapMarkerAlt, 
      title: t('contact.info.address.title'), 
      content: t('contact.info.address.text')
    },
    { 
      icon: FaInstagram, 
      title: t('contact.info.instagram.title'), 
      content: t('contact.info.instagram.handle'), 
      action: handleInstagramClick 
    }
  ];

  return (
    <div className="relative min-h-screen overflow-hidden">
      <SEOHead pageName="contact" />
      {/* Background decorative elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-white via-gray-50 to-white"></div>
        
        {/* Animated background blobs */}
        <div className="absolute top-1/4 -left-48 w-96 h-96 bg-[#896d44] rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob"></div>
        <div className="absolute top-1/3 -right-48 w-96 h-96 bg-[#896d44] rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob [animation-delay:2s]"></div>
        <div className="absolute bottom-1/4 left-1/2 transform -translate-x-1/2 w-96 h-96 bg-[#896d44] rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob [animation-delay:4s]"></div>
      </div>

      <div className="relative z-10">
        {/* Hero Section */}
        <section className="relative h-[70vh]">
          <div className="relative h-full w-full overflow-hidden">
            {/* Background Image with Parallax Effect */}
            <div className="absolute inset-0 scale-105 transform transition-transform duration-3000">
              <img
                src="https://cdn.prod.website-files.com/63d8b934a7a5a62ccb3f3f17/63d8da5b5fb7636befe61e3e_Patient%20Hero.webp"
                alt="Hero"
                className="object-cover w-full h-full transform scale-105 animate-slow-zoom brightness-75"
              />
            </div>

            {/* Modified Gradient Overlay */}
            <div className="absolute inset-0 bg-gradient-to-br from-black/90 via-black/70 to-[#896d44]/30"></div>

            {/* Content */}
            <div className="absolute inset-0 z-10 flex flex-col items-center justify-center text-white text-center px-4">
              <div className="max-w-4xl mx-auto animate-fade-in-up">
                <div className="w-20 h-1 bg-[#896d44] mx-auto mb-8"></div>
                <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight text-white">
                  {t('contact.hero.title')} <span className="text-[#896d44]">{t('contact.hero.titleHighlight')}</span>
                </h1>
                <p className="mt-4 text-lg md:text-xl max-w-2xl leading-relaxed font-light mx-auto text-gray-300">
                  {t('contact.hero.subtitle')}
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Info Section */}
        <section className="py-32 px-4">
          <div className="container mx-auto">
            <div className="text-center mb-20">
              <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
                {t('contact.section.title')} <span className="text-[#896d44]">{t('contact.section.titleHighlight')}</span>
              </h2>
              <p className="text-gray-600 max-w-3xl mx-auto text-lg leading-relaxed">
                {t('contact.section.subtitle')}
              </p>
            </div>

            <div className="bg-white/50 backdrop-blur-sm border border-[#896d44]/10 rounded-2xl overflow-hidden shadow-2xl">
              <div className="flex flex-col md:flex-row">
                {/* Contact Information */}
                <div className="w-full md:w-1/2 p-8 md:p-12">
                  <div className="space-y-8">
                    {contactItems.map((item, index) => (
                      <div key={index} className="flex items-start space-x-6 group">
                        <div className="flex-shrink-0 w-14 h-14 bg-[#896d44]/10 rounded-full flex items-center justify-center group-hover:bg-[#896d44]/20 transition-all duration-300">
                          <item.icon className="text-2xl text-[#896d44] group-hover:scale-110 transition-transform duration-300" />
                        </div>
                        <div className="flex-1">
                          <h3 className="text-xl font-semibold text-gray-900 mb-2">{item.title}</h3>
                          {item.action ? (
                            <button 
                              onClick={item.action}
                              className="text-lg text-gray-600 hover:text-[#896d44] transition-colors duration-300"
                            >
                              {item.content}
                            </button>
                          ) : (
                            <p className="text-lg text-gray-600">{item.content}</p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Map Section */}
                <div className="w-full md:w-1/2 p-8 md:p-12">
                  <div className="h-full rounded-xl overflow-hidden shadow-lg">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.2206281527647!2d21.154682876326167!3d42.65667981631007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549f099768b9c3%3A0x398e9e9e26591c45!2sDoctor%20I%C5%9EIK%20Clinic!5e0!3m2!1sen!2s!4v1732118730510!5m2!1sen!2s"
                      width="100%"
                      height="100%"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      className="w-full h-[500px]"
                      title={t('contact.map.title')}
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ContactPage;
