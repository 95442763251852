import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const SEOHead = ({ pageName }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  
  const baseUrl = 'https://doctorisikclinic.com';
  const pageTitle = `${t(`seo.${pageName}.title`)} | Doctor Işık Clinic`;
  const pageDescription = t(`seo.${pageName}.description`);

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="canonical" href={`${baseUrl}/${currentLanguage}`} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={baseUrl} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={`${baseUrl}/og-image.jpg`} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={baseUrl} />
      <meta property="twitter:title" content={pageTitle} />
      <meta property="twitter:description" content={pageDescription} />
      <meta property="twitter:image" content={`${baseUrl}/og-image.jpg`} />

      {/* Language Alternates */}
      <link rel="alternate" hrefLang="en" href={`${baseUrl}/en`} />
      <link rel="alternate" hrefLang="tr" href={`${baseUrl}/tr`} />
      <link rel="alternate" hrefLang="sq" href={`${baseUrl}/sq`} />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Dentist",
          "name": "Doctor Işık Clinic",
          "image": `${baseUrl}/clinic-image.jpg`,
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Rr. Perandori Justinian 131/1",
            "addressLocality": "Prishtine",
            "addressRegion": "Kosovo",
            "postalCode": "10000"
          },
          "url": baseUrl,
          "telephone": "+383048227225",
          "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
              "opens": "10:00",
              "closes": "18:30"
            },
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": "Saturday",
              "opens": "10:00",
              "closes": "14:30"
            }
          ]
        })}
      </script>
    </Helmet>
  );
};

export default SEOHead; 