import React from "react";
import { FaWhatsapp, FaArrowDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();

  const scrollToContent = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };

  return (
    <section className="relative h-screen">
      <div className="relative h-screen w-full overflow-hidden">
        {/* Background Image with Parallax Effect */}
        <div className="absolute inset-0 scale-105 transform transition-transform duration-3000">
          <img
            src="https://cdn.prod.website-files.com/63d8b934a7a5a62ccb3f3f17/63d8da5b5fb7636befe61e3e_Patient%20Hero.webp"
            alt="Hero"
            className="object-cover w-full h-full transform scale-105 animate-slow-zoom"
          />
        </div>

        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-black/70"></div>

        {/* Content */}
        <div className="absolute inset-0 z-10 flex flex-col items-center justify-center text-white text-center px-4">
          <div className="max-w-4xl mx-auto animate-fade-in-up">
            <h1 className="text-5xl md:text-6xl font-bold mb-6 h-32 md:h-24 leading-tight bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-300">
              {t('hero.title')}
            </h1>
            <p className="mt-4 text-lg md:text-xl max-w-2xl leading-relaxed font-light mx-auto text-gray-200">
              {t('hero.subtitle')}
            </p>
            <div className="mt-12 space-x-4 flex justify-center">
              <a
                href="https://wa.me/383048227225"
                target="_blank"
                rel="noopener noreferrer"
                className="group px-8 py-4 bg-[#896d44] bg-opacity-90 text-white flex items-center space-x-2 rounded-full text-lg font-semibold hover:bg-opacity-100 transition-all duration-300 transform hover:scale-105 hover:shadow-2xl"
              >
                <FaWhatsapp className="text-2xl group-hover:rotate-12 transition-transform duration-300" />
                <span>{t('hero.bookOnWhatsapp')}</span>
              </a>
            </div>
          </div>
        </div>

        {/* Scroll Indicator */}
        <button 
          onClick={scrollToContent}
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2 text-white animate-bounce cursor-pointer z-20"
        >
          <FaArrowDown className="text-3xl opacity-70 hover:opacity-100 transition-opacity duration-300" />
        </button>
      </div>
    </section>
  );
};

export default Hero;
