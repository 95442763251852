import React from "react";
import './i18n'; 
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar.jsx";
import Footer from "./components/footer.jsx";
import ServicesPage from "./components/ServicesPage.jsx"; // Import the Services page
import Home from "./components/Home.jsx"; // Import the Home page
import ContactPage from "./components/ContactPage.jsx";


function App() {
  return (
    <Router>
      
      <div className="App">
        <Navbar />
        {/* Define Routes for different pages */}
        <Routes> {/* Use element instead of component */}
          <Route path="/" element={<Home />} /> {/* Home Page */}
          <Route path="/services" element={<ServicesPage />} /> 
          <Route path="/contact" element={<ContactPage />} /> 
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;