import React from "react";
import { FaPhone, FaWhatsapp, FaEnvelope, FaMapMarkerAlt, FaInstagram } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const InfoPointSection = () => {
  const { t } = useTranslation();

  const phoneNumber1 = "+38348227225"; // WhatsApp & Viber number
  const phoneNumber2 = "+38348227220"; // Regular phone number
  const email = "info@doctorisikclinic.com";
  const address = "Rr. Perandori Justinian 131/1 Qyteza Pejton - Prishtine";
  const instagram = "@doctorisikclinic";
  const slogan = "More Than A Dentistry";

  const handlePhoneClick = () => {
    window.open(`https://wa.me/${phoneNumber1}`, "_blank");
  };

  const handleInstagramClick = () => {
    window.open(`https://www.instagram.com/${instagram.replace('@', '')}`, "_blank");
  };

  return (
    <section className="my-32">
      <div className="relative py-12">
        <div className="bg-white rounded-2xl shadow-2xl overflow-hidden">
          <div className="flex flex-col md:flex-row">
            {/* Contact Information */}
            <div className="w-full md:w-1/2 p-8 md:p-12 bg-gradient-to-br from-white to-gray-50">
              <h2 className="text-4xl font-extrabold mb-8 text-[#896d44]">{t('contact.title')}</h2>
              
              <div className="space-y-6">
                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-[#896d44] bg-opacity-10 rounded-full flex items-center justify-center">
                    <FaWhatsapp className="text-2xl text-[#896d44]" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-[#896d44] mb-2">{t('contact.whatsapp')}</h3>
                    <button 
                      onClick={handlePhoneClick}
                      className="text-lg text-gray-600 hover:text-[#896d44] transition-colors duration-300"
                    >
                      {phoneNumber1}
                    </button>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-[#896d44] bg-opacity-10 rounded-full flex items-center justify-center">
                    <FaPhone className="text-2xl text-[#896d44]" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-[#896d44] mb-2">{t('contact.phone')}</h3>
                    <p className="text-lg text-gray-600">{phoneNumber2}</p>
                    <p className="text-lg text-gray-600">{phoneNumber1}</p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-[#896d44] bg-opacity-10 rounded-full flex items-center justify-center">
                    <FaEnvelope className="text-2xl text-[#896d44]" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-[#896d44] mb-2">{t('contact.email')}</h3>
                    <p className="text-lg text-gray-600">{email}</p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-[#896d44] bg-opacity-10 rounded-full flex items-center justify-center">
                    <FaMapMarkerAlt className="text-2xl text-[#896d44]" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-[#896d44] mb-2">{t('contact.address')}</h3>
                    <p className="text-lg text-gray-600">{address}</p>
                  </div>
                </div>

                <div className="flex items-start space-x-4">
                  <div className="flex-shrink-0 w-12 h-12 bg-[#896d44] bg-opacity-10 rounded-full flex items-center justify-center">
                    <FaInstagram className="text-2xl text-[#896d44]" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-[#896d44] mb-2">{t('contact.instagram')}</h3>
                    <button 
                      onClick={handleInstagramClick}
                      className="text-lg text-gray-600 hover:text-[#896d44] transition-colors duration-300"
                    >
                      {instagram}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Google Map Section */}
            <div className="w-full md:w-1/2 p-8 md:p-12 bg-white">
              <h2 className="text-4xl font-extrabold mb-8 text-[#896d44]">{t('contact.location')}</h2>
              <div className="rounded-xl overflow-hidden shadow-lg h-[400px]">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.2206281527647!2d21.154682876326167!3d42.65667981631007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549f099768b9c3%3A0x398e9e9e26591c45!2sDoctor%20I%C5%9EIK%20Clinic!5e0!3m2!1sen!2s!4v1732118730510!5m2!1sen!2s"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  className="w-full h-full"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoPointSection;
