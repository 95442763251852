import React, { useState, useEffect } from 'react';
import { FaPhone, FaFacebookF, FaYoutube, FaInstagram, FaWhatsapp, FaMapMarkerAlt, FaEnvelope, FaClock, FaTools, FaTooth } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import LogowithoutText from '../assets/images/Logo-withoutext.png'
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = (path) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(path);
  };

  const services = [
    "veneers",
    "aesthetic",
    "endodontics",
    "periodontics",
    "prosthodontics",
    "pedodontics",
    "oralSurgery",
    "implants",
    "invisalign"
  ];

  const socialLinks = [
    { icon: FaInstagram, href: "https://www.instagram.com/doctorisikclinic/", label: "instagram" },
    { icon: FaWhatsapp, href: "https://wa.me/383048227225", label: "whatsapp" }
  ];

  const contactInfo = [
    { icon: FaMapMarkerAlt, text: t('footer.sections.contact.clinic'), key: 'clinic' },
    { icon: FaEnvelope, text: t('footer.sections.contact.email'), key: 'email' },
    { icon: FaPhone, text: t('footer.sections.contact.phone'), key: 'phone' },
    { icon: FaClock, text: `${t('footer.sections.contact.hours')}`, key: 'hours' }
  ];

  return (
    <>
      {/* Sticky Footer */}
      <footer className={`transition-all duration-500 ${
        isSticky ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-full'
      } fixed bottom-0 w-full bg-gradient-to-r from-black via-[#896d44]/30 to-black backdrop-blur-md shadow-xl z-50 border-t border-[#896d44]/10`}>
        <div className="container mx-auto flex justify-between items-center px-4 md:px-8 py-4">
          <div className="flex items-center space-x-4 group">
            <div className="bg-[#896d44]/10 p-2 rounded-full group-hover:bg-[#896d44]/20 transition-all duration-300">
              <FaPhone className="text-[#896d44] group-hover:scale-110 transition-transform duration-300" />
            </div>
            <a href="tel:+38348227225" className="text-md md:text-lg font-semibold text-white group-hover:text-[#896d44] transition-colors duration-300">
              {t('footer.sections.contact.phone')}
            </a>
          </div>
          <div className="flex items-center space-x-6">
            {socialLinks.map((social, index) => (
              <a 
                key={index}
                href={social.href} 
                target="_blank" 
                rel="noreferrer"
                className="group relative p-2 hover:bg-[#896d44]/10 rounded-full transition-all duration-300"
                aria-label={t(`footer.socialMedia.${social.label}`)}
              >
                <social.icon className="text-white/80 group-hover:text-[#896d44] text-xl transition-all duration-300 group-hover:scale-110" />
              </a>
            ))}
          </div>
        </div>
      </footer>

      {/* Main Footer */}
      <section className="bg-gradient-to-b from-black via-black/95 to-[#896d44]/30 text-white py-16 relative mb-16">
        <div className="container mx-auto px-6">
          {/* Top Section with Logo and Description */}
          <div className="max-w-3xl mx-auto text-center mb-16">
            <Link to="/" className="inline-flex items-center justify-center group mb-6">
              <div className="bg-[#896d44]/10 p-3 rounded-full transition-all duration-300 group-hover:bg-[#896d44]/20">
                <img src={LogowithoutText} alt="Logo" className="h-12 w-auto transition-transform duration-300 group-hover:scale-110" />
              </div>
              <span className="ml-4 text-2xl font-semibold tracking-wider group-hover:text-[#896d44] transition-colors duration-300">
                DOCTOR IŞIK
              </span>
            </Link>
            <p className="text-lg leading-relaxed text-gray-300 max-w-2xl mx-auto">
              {t('footer.description')}
            </p>
          </div>

          {/* Grid Sections */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 md:gap-8 lg:gap-1 mb-16">
            {/* Services Section */}
            <div className="bg-black/20 rounded-2xl p-6 backdrop-blur-sm border border-[#896d44]/10">
              <h4 className="text-xl font-semibold text-gray-300 mb-6 flex items-center">
                <span className="bg-[#896d44]/10 p-2 rounded-full mr-3">
                  <FaTooth className="text-[#896d44]" />
                </span>
                {t('footer.sections.services.title')}
              </h4>
              <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {services.map((service) => (
                  <li 
                    key={service} 
                    className="text-gray-300 hover:text-[#896d44] transition-all duration-300 cursor-pointer hover:translate-x-1 text-sm md:text-base flex items-start"
                  >
                    <span className="mr-2 inline-block">•</span>
                    <span>{t(`footer.sections.services.items.${service}`)}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div></div>

            {/* Contact Information */}
            <div className="bg-black/20 rounded-2xl p-6 backdrop-blur-sm border border-[#896d44]/10">
              <h4 className="text-xl font-semibold text-gray-300 mb-6 flex items-center">
                <span className="bg-[#896d44]/10 p-2 rounded-full mr-3">
                  <FaEnvelope className="text-[#896d44]" />
                </span>
                {t('footer.sections.contact.title')}
              </h4>
              <div className="space-y-4">
                {contactInfo.map((item) => (
                  <div key={item.key} className="flex items-center space-x-3 text-gray-300 group hover:text-[#896d44] transition-all duration-300 cursor-pointer">
                    <span className="bg-[#896d44]/10 p-2 rounded-full group-hover:bg-[#896d44]/20 transition-all duration-300">
                      <item.icon className="text-[#896d44] group-hover:scale-110 transition-transform duration-300" />
                    </span>
                    <span className="group-hover:translate-x-1 transition-transform duration-300">{item.text}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Copyright Section */}
          <div className="text-center border-t border-[#896d44]/10 pt-8">
            <p className="text-sm text-gray-200">
              {t('footer.copyright')}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
