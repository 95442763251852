import React from "react";
import { FaWhatsapp, FaTooth, FaSmile, FaTeeth, FaSyringe, FaMagic, FaRegSmile, FaStethoscope, FaUserMd, FaChild } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import SEOHead from './SEOHead';
const ServicesPage = () => {
  const { t } = useTranslation();

  const servicesData = [
    {
      icon: FaTooth,
      title: t('services.items.veneers.title'),
      description: t('services.items.veneers.description')
    },
    {
      icon: FaTeeth,
      title: t('services.items.implants.title'),
      description: t('services.items.implants.description')
    },
    {
      icon: FaRegSmile,
      title: t('services.items.invisalign.title'),
      description: t('services.items.invisalign.description')
    },
    {
      icon: FaUserMd,
      title: t('services.items.endodontics.title'),
      description: t('services.items.endodontics.description')
    },
    {
      icon: FaTooth,
      title: t('services.items.periodontics.title'),
      description: t('services.items.periodontics.description')
    },
    {
      icon: FaChild,
      title: t('services.items.pedodontics.title'),
      description: t('services.items.pedodontics.description')
    }
  ];

  return (
    <div className="relative min-h-screen overflow-hidden">
      <SEOHead pageName="services" />
      {/* Background decorative elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-white via-gray-50 to-white"></div>
        
        {/* Animated background blobs */}
        <div className="absolute top-1/4 -left-48 w-96 h-96 bg-[#896d44] rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob"></div>
        <div className="absolute top-1/3 -right-48 w-96 h-96 bg-[#896d44] rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob [animation-delay:2s]"></div>
        <div className="absolute bottom-1/4 left-1/2 transform -translate-x-1/2 w-96 h-96 bg-[#896d44] rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob [animation-delay:4s]"></div>
      </div>

      <div className="relative z-10">
        {/* Hero Section */}
        <section className="relative h-[70vh]">
          <div className="relative h-full w-full overflow-hidden">
            {/* Background Image with Parallax Effect */}
            <div className="absolute inset-0 scale-105 transform transition-transform duration-3000">
              <img
                src="https://cdn.prod.website-files.com/63d8b934a7a5a62ccb3f3f17/63d8da5b5fb7636befe61e3e_Patient%20Hero.webp"
                alt={t('services.hero.title')}
                className="object-cover w-full h-full transform scale-105 animate-slow-zoom brightness-75"
              />
            </div>

            {/* Modified Gradient Overlay */}
            <div className="absolute inset-0 bg-gradient-to-br from-black/80 via-black/60 to-[#896d44]/30"></div>

            {/* Content */}
            <div className="absolute inset-0 z-10 flex flex-col items-center justify-center text-white text-center px-4">
              <div className="max-w-4xl mx-auto animate-fade-in-up">
                <div className="w-20 h-1 bg-[#896d44] mx-auto mb-8"></div>
                
                <h1 className="text-4xl md:text-6xl font-bold mb-6 leading-tight text-white">
                  {t('services.hero.title')} <span className="text-[#896d44]">{t('services.hero.titleHighlight')}</span>
                </h1>
                <p className="mt-4 text-lg md:text-xl max-w-2xl leading-relaxed font-light mx-auto text-gray-300">
                  {t('services.hero.subtitle')}
                </p>
                
                <div className="mt-8 flex justify-center">
                  <a
                    href="https://wa.me/383048227225"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group px-6 py-3 bg-transparent border-2 border-[#896d44] text-white flex items-center space-x-2 rounded-full text-lg font-medium hover:bg-[#896d44] transition-all duration-300"
                  >
                    <FaWhatsapp className="text-xl group-hover:rotate-12 transition-transform duration-300" />
                    <span>{t('services.hero.button')}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Services Grid Section */}
        <section className="py-32 px-4">
          <div className="container mx-auto">
            <div className="text-center mb-20">
              <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
                {t('services.section.title')} <span className="text-[#896d44]">{t('services.section.titleHighlight')}</span>
              </h2>
              <p className="text-gray-600 max-w-3xl mx-auto text-lg leading-relaxed">
                {t('services.section.subtitle')}
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {servicesData.map((service, index) => (
                <div
                  key={index}
                  className="group relative overflow-hidden bg-white/50 backdrop-blur-sm border border-[#896d44]/10 p-8 rounded-2xl shadow-lg hover:shadow-2xl transition-all duration-500"
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-[#896d44]/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
                  <div className="relative z-10">
                    <div className="flex justify-center items-center mb-6">
                      <span className="bg-[#896d44]/10 p-6 rounded-full group-hover:bg-[#896d44]/20 transition-all duration-300">
                        <service.icon className="text-4xl text-[#896d44] group-hover:scale-110 transition-transform duration-300" />
                      </span>
                    </div>
                    <h3 className="text-2xl font-bold text-center mb-4 text-[#896d44] group-hover:text-[#896d44] transition-colors duration-300">
                      {service.title}
                    </h3>
                    <p className="text-gray-600 text-center leading-relaxed mb-6">
                      {service.description}
                    </p>
                    <div className="flex justify-center">
                      
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ServicesPage;
