import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faTooth, faClipboardList, faHeart } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ProcessSection = () => {
  const { t } = useTranslation();

  const steps = [
    {
      icon: faCalendarCheck,
      title: t('home.process.steps.consultation.title'),
      description: t('home.process.steps.consultation.description'),
    },
    {
      icon: faTooth,
      title: t('home.process.steps.planning.title'),
      description: t('home.process.steps.planning.description'),
    },
    {
      icon: faClipboardList,
      title: t('home.process.steps.treatment.title'),
      description: t('home.process.steps.treatment.description'),
    },
    {
      icon: faHeart,
      title: t('home.process.steps.aftercare.title'),
      description: t('home.process.steps.aftercare.description'),
    },
  ];

  return (
    <section className="my-32">
      <div className="text-center mb-20">
        <div className="w-20 h-1 bg-[#896d44] mx-auto mb-8"></div>
        <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
          {t('home.process.title')} <span className="text-[#896d44]">{t('home.process.titleHighlight')}</span>
        </h2>
        <p className="text-gray-600 max-w-3xl mx-auto text-lg leading-relaxed">
          {t('home.process.subtitle')}
        </p>
      </div>

      <div className="relative py-12">
        <div className="hidden md:block absolute left-1/2 top-0 bottom-0 w-0.5 bg-[#896d44] bg-opacity-20 transform -translate-x-1/2" />

        <div className="space-y-12 relative">
          {steps.map((step, index) => (
            <div key={index} className={`flex flex-col md:flex-row ${
              index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
            } items-center gap-8 md:gap-16`}>
              {/* Icon Container */}
              <div className="relative flex-1 flex md:justify-end">
                <div className="w-24 h-24 rounded-full bg-[#896d44] bg-opacity-10 flex items-center justify-center transform transition-transform duration-300 hover:scale-110 group">
                  <FontAwesomeIcon 
                    icon={step.icon} 
                    className="w-12 h-12 text-[#896d44] group-hover:text-[#6d543a] transition-colors duration-300" 
                  />
                </div>
                {/* Timeline dot */}
                <div className="hidden md:block absolute top-1/2 w-4 h-4 rounded-full bg-[#896d44] transform -translate-y-1/2 right-[-2rem]" 
                  style={{ 
                    right: index % 2 === 0 ? '-2rem' : 'auto',
                    left: index % 2 === 0 ? 'auto' : '-2rem'
                  }} 
                />
              </div>

              {/* Content Container */}
              <div className="flex-1 text-center md:text-left">
                <div className="bg-gray-50 rounded-xl p-6 shadow-sm hover:shadow-md transition-shadow duration-300">
                  <h2 className="text-2xl font-bold mb-3 text-[#896d44]">
                    {step.title}
                  </h2>
                  <p className="text-gray-600 leading-relaxed">
                    {step.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="mt-16 text-center">
        <a
          href="https://wa.me/383048227225"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center px-8 py-4 bg-[#896d44] text-white rounded-full font-semibold hover:bg-[#6d543a] transition-colors duration-300 transform hover:scale-105"
        >
          {t('home.process.startToday')}
        </a>
      </div>
    </section>
  );
};

export default ProcessSection;