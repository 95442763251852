import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { t } = useTranslation();
  const faqs = [
    {
      question: t('home.faq.questions.q1.question'),
      answer: t('home.faq.questions.q1.answer')
    },
    {
      question: t('home.faq.questions.q2.question'),
      answer: t('home.faq.questions.q2.answer')
    },
    {
      question: t('home.faq.questions.q3.question'),
      answer: t('home.faq.questions.q3.answer')
    },
    { 
      question: t('home.faq.questions.q4.question'),
      answer: t('home.faq.questions.q4.answer')
    },
  ];

  const toggleFAQ = (index) => setActiveIndex(activeIndex === index ? null : index);

  return (
    <section className="my-32">
      <div className="relative py-12">
        <div className="bg-white rounded-2xl shadow-2xl p-8 md:p-12">
          <h1 
            className="text-4xl md:text-5xl font-bold mb-12 text-center text-[#896d44]" 
            style={{ fontFamily: "Comfortaa" }}
          >
            {t('home.faq.title')} <span className="text-[#896d44]">{t('home.faq.titleHighlight')}</span>
          </h1>
          
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div 
                key={index} 
                className="border border-gray-100 rounded-xl overflow-hidden shadow-sm"
              >
                <button
                  className={`w-full text-left p-6 flex justify-between items-center transition-all duration-300 ${
                    activeIndex === index 
                      ? 'bg-[#896d44] text-white' 
                      : 'bg-white text-gray-800 hover:bg-gray-50'
                  }`}
                  onClick={() => toggleFAQ(index)}
                >
                  <h2 className="text-xl font-semibold pr-4">
                    {faq.question}
                  </h2>
                  <span className="flex-shrink-0">
                    {activeIndex === index ? (
                      <FaChevronUp className={`w-5 h-5 ${
                        activeIndex === index ? 'text-white' : 'text-[#896d44]'
                      }`} />
                    ) : (
                      <FaChevronDown className={`w-5 h-5 ${
                        activeIndex === index ? 'text-white' : 'text-[#896d44]'
                      }`} />
                    )}
                  </span>
                </button>
                
                <div 
                  className={`transition-all duration-300 ease-in-out overflow-hidden ${
                    activeIndex === index ? 'max-h-48' : 'max-h-0'
                  }`}
                >
                  <div className="p-6 bg-gray-50">
                    <p className="text-gray-600 text-lg leading-relaxed">
                      {faq.answer}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Contact CTA */}
          <div className="mt-12 text-center">
            <p className="text-gray-600 mb-4">
              {t('home.faq.stillHaveQuestions')}
            </p>
            <a
              href="https://wa.me/383048227225"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-6 py-3 bg-[#896d44] text-white rounded-full font-semibold hover:bg-[#6d543a] transition-colors duration-300"
            >
              {t('home.faq.contactUs')}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
