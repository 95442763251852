import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaBars, FaTimes, FaGlobe } from 'react-icons/fa';
import LogowithoutText from '../assets/images/Logo-withoutext.png';

// Enhanced LanguageButton component
const LanguageButton = ({ lang, currentLang, onClick }) => (
  <button
    onClick={() => onClick(lang)}
    className={`w-full px-6 py-3 flex items-center justify-between transition-all duration-300 hover:bg-[#896d44]/10 group ${
      currentLang === lang 
        ? 'bg-gradient-to-r from-[#896d44] to-[#896d44]/80 text-white' 
        : 'text-white/90 hover:text-white'
    }`}
  >
    <div className="flex items-center space-x-3">
      <span className={`font-medium transition-all duration-300 ${
        currentLang === lang ? 'text-white' : 'group-hover:text-[#896d44]'
      }`}>{lang.toUpperCase()}</span>
    </div>
    {currentLang === lang && (
      <span className="text-xs bg-white/20 px-3 py-1 rounded-full shadow-inner backdrop-blur-sm">
        Active
      </span>
    )}
  </button>
);

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const lastScrollY = useRef(0);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      // Always show navbar when at top of page
      if (currentScrollY < 50) {
        setIsVisible(true);
        setIsScrolled(false);
        return;
      }

      setIsScrolled(true);
      
      // Hide navbar when scrolling down, unless mobile menu is open
      if (!isMobileMenuOpen) {
        setIsVisible(false);
      }
    };

    const handleMouseMove = (e) => {
      // Show navbar when mouse is near top of screen (within 100px)
      if (e.clientY < 100) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    setIsDropdownOpen(false);
    setIsMobileMenuOpen(false);
  }, [location]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".dropdown-button") && !event.target.closest(".dropdown-menu")) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setIsDropdownOpen((prev) => !prev);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setIsDropdownOpen(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <nav className={`fixed w-full z-50 transition-all duration-500 ${
      isScrolled 
        ? 'bg-gradient-to-r from-black/85 via-[#896d44]/20 to-black/85 backdrop-blur-md shadow-lg border-b border-[#896d44]/10' 
        : 'bg-gradient-to-b from-black/50 to-transparent'
    } ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          {/* Logo - Updated with onClick handler */}
          <button 
            onClick={() => handleNavigation('/')} 
            className="flex items-center space-x-3 group"
          >
            <div className={`rounded-full p-1 transition-all duration-500 ${
              isScrolled ? 'bg-[#896d44]/10' : 'bg-transparent'
            }`}>
              <img 
                src={LogowithoutText} 
                alt="Logo" 
                className="h-10 w-auto transition-all duration-300 group-hover:scale-110 group-hover:rotate-3" 
              />
            </div>
            <span className={`font-semibold tracking-wider text-lg transition-all duration-500 ${
              isScrolled 
                ? 'text-white group-hover:text-white' 
                : 'text-white group-hover:text-[#896d44]'
            }`}>
              DOCTOR IŞIK
            </span>
          </button>

          {/* Navigation Links - Update each link */}
          <div className="hidden md:flex items-center space-x-8">
            {[
              { path: '/services', label: t('navbar.services') },
              { path: '/contact', label: t('navbar.contact') },
            ].map((item) => (
              <button
                key={item.path}
                onClick={() => handleNavigation(item.path)}
                className={`text-white hover:text-[#896d44] transition-colors duration-300 ${
                  location.pathname === item.path ? 'text-[#896d44]' : ''
                }`}
              >
                {item.label}
              </button>
            ))}

            {/* Language Selector Button */}
            <div className="relative">
              <button 
                onClick={toggleDropdown}
                className="dropdown-button flex items-center space-x-2 text-white hover:text-[#896d44] transition-all duration-300 group py-2 px-3 rounded-lg hover:bg-white/5"
              >
                <FaGlobe className={`text-lg transition-transform duration-300 ${
                  isDropdownOpen ? 'rotate-180' : ''
                }`} />
                <span className="font-medium">{i18n.language.toUpperCase()}</span>
              </button>

              {/* Dropdown Menu */}
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 overflow-hidden dropdown-menu transform transition-all duration-300">
                  <div className="backdrop-blur-md bg-gradient-to-b from-black/95 via-black/90 to-[#896d44]/20 rounded-2xl shadow-2xl border border-[#896d44]/20">
                    <div className="py-2">
                      <div className="px-6 py-3 text-sm text-gray-400 border-b border-[#896d44]/10">
                        <div className="flex items-center space-x-2">
                          <FaGlobe className="text-[#896d44]" />
                          <span>{t('navbar.language')}</span>
                        </div>
                      </div>
                      {["shq", "en", "tur"].map((lang) => (
                        <LanguageButton 
                          key={lang}
                          lang={lang}
                          currentLang={i18n.language}
                          onClick={handleLanguageChange}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden flex items-center space-x-4">
            <button 
              onClick={toggleMobileMenu}
              className="text-white hover:text-[#896d44] transition-all duration-300 p-2 rounded-full hover:bg-white/5"
            >
              {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden transition-all duration-500 ease-in-out ${
        isMobileMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
      } overflow-hidden bg-gradient-to-b from-black/95 via-black/90 to-[#896d44]/20 backdrop-blur-md border-t border-[#896d44]/10`}>
        <div className="px-4 py-4 space-y-2">
          {/* Navigation Links */}
          <div className="space-y-1">
            <Link
              to="/services"
              className="block px-4 py-3 text-white hover:text-[#896d44] hover:bg-white/5 rounded-xl transition-all duration-200"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {t('navbar.services')}
            </Link>
            <Link
              to="/contact"
              className="block px-4 py-3 text-white hover:text-[#896d44] hover:bg-white/5 rounded-xl transition-all duration-200"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              {t('navbar.contact')}
            </Link>
          </div>

          {/* Mobile Language Selector */}
          <div className="mt-6 pt-4 border-t border-[#896d44]/10">
            <div className="px-4 text-sm text-gray-400 mb-3 flex items-center space-x-2">
              <FaGlobe className="text-[#896d44]" />
              <span>Select Language</span>
            </div>
            <div className="space-y-1 bg-black/20 rounded-xl p-2">
              {["shq", "en", "tur"].map((lang) => (
                <button
                  key={lang}
                  onClick={() => {
                    handleLanguageChange(lang);
                    setIsMobileMenuOpen(false);
                  }}
                  className={`w-full text-left px-4 py-3 rounded-lg transition-all duration-300 ${
                    i18n.language === lang 
                      ? 'bg-gradient-to-r from-[#896d44] to-[#896d44]/80 text-white' 
                      : 'text-white/90 hover:text-white hover:bg-[#896d44]/10'
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <span className="font-medium">{lang.toUpperCase()}</span>
                    {i18n.language === lang && (
                      <span className="text-xs bg-white/20 px-3 py-1 rounded-full shadow-inner backdrop-blur-sm">
                        Active
                      </span>
                    )}
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
