import React from "react";
import { useTranslation } from 'react-i18next';
import Hero from "./hero.jsx";
import ProcessSection from "./processSection.jsx";
import FAQSection from "./FAQSection.jsx";
import InfoPointSection from "./infoPointSection.jsx";


const Home = () => {
  const { t } = useTranslation();
  
  return (
    <div className="relative min-h-screen overflow-hidden">
      
      {/* Background decorative elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-white via-gray-50 to-white"></div>
        
        {/* Animated background blobs */}
        <div className="absolute top-1/4 -left-48 w-96 h-96 bg-[#896d44] rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob"></div>
        <div className="absolute top-1/3 -right-48 w-96 h-96 bg-[#896d44] rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob [animation-delay:2s]"></div>
        <div className="absolute bottom-1/4 left-1/2 transform -translate-x-1/2 w-96 h-96 bg-[#896d44] rounded-full mix-blend-multiply filter blur-3xl opacity-20 animate-blob [animation-delay:4s]"></div>
      </div>

      {/* Content */}
      <div className="relative z-10">
        <Hero />
        <div className="relative">
          <div className="absolute inset-0 bg-[#896d44] opacity-5 skew-y-3 h-[120%] -mt-20"></div>
          <div className="relative z-10 max-w-7xl mx-auto px-4">
            <ProcessSection />
            <FAQSection />
            <InfoPointSection />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
