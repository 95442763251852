import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationSHQ from './locales/shq/translation.json';
import translationTUR from './locales/tur/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  shq: {
    translation: translationSHQ,
  },
  tur: {
    translation: translationTUR,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en', // default language
    debug: true,
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
